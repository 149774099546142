<template>
  <div class="message">
    <van-nav-bar  left-arrow  :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
                  class="nav" @click-right="go">
      <template #title>
        <p class="title">消息</p>
      </template>
    </van-nav-bar>
    <div class="msg-box">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="refreshBody" >

      <van-list
        v-if="firstShow || listData.length > 0"
        v-model:loading="loading"
        v-model:error = "error"
        :finished="finished"
        finished-text="已经到底了"
        error-text="加载出错,请重新加载"
        @load="onLoad"
      >

        <div v-for="(item, index) in listData" :key="index" class="message-item">
          <van-image height='50px' width="50px" :src="item.image" />
          <div class="div-msg">
            <div class="msg-title">
              <span class="title">{{ item.name }}</span> <span class="title-items">{{ item.createTime }}</span>
            </div>
            <p class="desc-content">
              {{ item.content }}
            </p>
          </div>

        </div>
      </van-list>

        <div v-else class="nodata">
          <img src="@/assets/common/icon_nodata.png" alt="">
          暂无消息
        </div>
    </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import { clearToast, createNonceStr, showFailToast, showLoading } from "../../utils/util";
export default {
  name: "message",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const showDetail = ref(false);

    let productImgs = ref("");

    const state = reactive({
      listData: [],
      loadingMore: false,
      loading:false,
      finished:false,
      refreshing:false,
      error:false,
      offset: 0,
      limit: 15,
      firstShow:true
    });
    let productId = $router.currentRoute.value.params.productId
    onMounted(async () => {
    });
    const goBack = (() => {
      $router.back(-1)
    })
    const onLoad = (() => {
      loadData()
    })


    const loadData = () => {
      showLoading()
      proxy.$http.get("/wenwu-pubapi-services/notice/list?offset=" + state.offset + "&limit=" + state.limit).then(res => {
        if (res.data.code == 200) {
          state.firstShow = false;
          clearToast()
          if(state.offset == 0){
            state.listData = [];
          }
          state.listData.push(...res.data.data);
          state.finished=false
          state.loading=false
          if(res.data.data.length<5){
            state.finished=true
          }else{
            state.offset+=state.limit
          }
        } else {
          showFailToast(res.data.message)
          state.error = true;
          state.finished = false;
          state.loading = false;
        }
      }).catch((err)=>{
        showFailToast(err.message)
      })
    }
    const onRefresh=(()=>{
      state.offset=0;
      state.finished=true
      state.loading=true
      state.refreshing=false
      loadData()
    })
    return {
      goBack,
      ...toRefs(state),
      productId,
      onLoad,
      onRefresh
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


